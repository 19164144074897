require('../css/style.css');
const $ = require('jquery');

import 'slick-carousel/slick/slick';



jQuery(document).ready(function(){
    // MOUSE CLICK ACCOUNT
    jQuery('header div.account').click(function(){
        jQuery('header nav.accountNav').toggleClass('active');
    })

    jQuery('#slider').slick({
        dots: false,
        infinite: false,
        arrows: false,
        fade: true,
    })

    jQuery('#slider li.next').bind('click', function(){
        jQuery('#slider').slick('slickNext');
    })

    jQuery('#slider li.prev').bind('click', function(){
        jQuery('#slider').slick('slickPrev');
    })

 

     // Menu burger
     var burger = $('.menu-trigger');
     burger.each(function(index){
         var $this = $(this);
         
         $this.on('click', function(e){
             e.preventDefault();
             $(this).toggleClass('active-7');
             jQuery('nav.menu').toggleClass('active');
         })
     });
 

})